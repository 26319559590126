.login-wrapper {
    background-color: $primaryRed;

    a.shape-arrow {
        position: absolute;
        left: 25px;
        bottom: 25px;
    }

    header.header {
        padding-top: 30px;
        padding-left: 30px;
        user-select: none;

        .brand {
            .name {
                font-size: 2.5rem;
                color: #FFF;
            }
        }
    }

    form.login-form {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        padding: 40px 15px 15px 15px;
        border-radius: 15px;
        box-shadow: 0 0 10px 0 #333;
        background-color: #FFF;

        .input-area {
            margin-bottom: 25px;
        }

        .checkbox {
            display: block;
            margin-bottom: 10px;
        }

        .server-error {
            color: $primaryRed;
            font-size: .8rem;
        }

        .form-actions {
            margin-top: 25px;

            input[type="submit"] {
                display: block;
                margin: 0 auto;
            }
        }
    }

    .github-btn {
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
        padding: 0;
        height: 40px;
        width: 40px;
        border-radius: 50%;

        svg {
            vertical-align: middle;
        }

        &:hover {
            background-color: #FFF;

            svg path {
                fill: $primaryRed;
            }
        }
    }
}