%btn {
    cursor: pointer;
    background-color: transparent;
    padding: 10px 24px;
    border-radius: 5px;
    border: 2px solid transparent;
    box-shadow: 0 2px 4px -2px #999;

    &:focus {
        border: 2px solid #23e8e8;
    }
}

.btn-primary {
    @extend %btn;
    background-color: $secondaryBlue;
    color: #FFF;
    @include btn-hover-color($secondaryBlue);
}

.btn-secondary {
    @extend %btn;
    background-color: $thirdLightBrown;
    color: $secondaryBlue;
    @include btn-hover-color($thirdLightBrown);
}

.btn-icon-svg {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 24px;
    padding-left: 36px;

    svg {
        @include center-vertically('absolute');
        vertical-align: middle;
        left: 10px;
    }
}