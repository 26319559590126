*, *:after, *:before {
    box-sizing: border-box;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
}

ol, ul {
    list-style: none;
}

a {
    text-decoration: none;
}

button, input {
    &:focus {
        outline: none;
    }
}

input::placeholder {
    color: inherit;
}

table {
    border-collapse: collapse;
}
