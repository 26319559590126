*, *:before, *:after {
    font-family: Arvo, Helvetica, sans-serif;
}

button {
    text-transform: capitalize;
}

.homepage-wrapper, .login-wrapper, .error-container {
    position: fixed;
    width: 100%;
    height: 100%;
}

.homepage-wrapper, .login-wrapper {
    svg.shape-right,
    svg.shape-left {
        position: absolute;
        bottom: 0;
        z-index: -1;
    }

    svg.shape-left {
        left: 0;
    }
    svg.shape-right {
        right: 0;
    }
}